import { useAuthStore } from '@/store/auth';

export default defineNuxtRouteMiddleware(() => {
    const authStore = useAuthStore();
    const authCookie = useAuthCookie();
    if (!authCookie) {
        return '/signup';
    }
    const token = authCookie.accessToken;
    authStore.authenticate(!!token);
    authStore.parseJWT(token);

    if (!authStore.isAuthenticated || !authStore.isActive) {
        return '/signup';
    }
    if (authStore.isSubscription) {
        return '/';
    }
});
